<template>
  <m-card :title="$t('shared.add')">
    <v-form>
      <v-row dense>
        <v-col cols="12" sm="6" md="4">
          <c-input v-model="model.pin" :label="$t('coupons.pin')" :rules="'required|max:30'" dir="ltr" />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <c-input
            select
            v-model="model.discountType"
            :label="$t('coupons.discountType')"
            :rules="'required'"
            :items="discountTypes"
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <c-input
            v-model="model.amount"
            :label="$t('shared.discount')"
            dir="ltr"
            :suffix="discountLabel"
            :rules="discountValidations"
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <c-input
            v-model="model.claimLimit"
            :label="$t('coupons.claimLimit')"
            :rules="'numeric'"
            :suffix="$t('shared.times')"
            dir="ltr"
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <date-picker v-model="model.availableFrom" :label="$t('coupons.availableFrom')" />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <date-picker v-model="model.availableUntil" :label="$t('coupons.availableUntil')" />
        </v-col>

        <v-col cols="12" sm="6" md="4" class="d-flex align-center">
          <v-switch v-model="model.reusable" :label="$t('coupons.reusable')" inset />
        </v-col>

        <v-col cols="12" sm="6" md="4" class="d-flex align-center">
          <v-switch v-model="model.active" :label="$t('shared.active')" inset />
        </v-col>
      </v-row>
    </v-form>

    <template #actions>
      <page-footer @submit="submit" :loading="progressing" />
    </template>
  </m-card>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Create',
  mixins: [EditForm],
  methods: {
    submit() {
      this.$preSubmit('coupon').then((proceed) => {
        if (proceed) {
          this.$api.coupons
            .create(this.model)
            .then((res) => {
              this.model = res
              this.progressing = false
              this.$showSuccess(this.$t('coupons.couponAdded'))
            })
            .catch((err) => {
              this.progressing = false
              this.$showError(err)
            })
        }
      })
    }
  }
}
</script>
